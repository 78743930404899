export default class WorkerBlueprintsApi {
  constructor({
    getMoreItems,
    getItems,
    getContainer,
    getStyles,
    onBlueprintReady,
    getTotalItemsCount,
  }) {
    this.getMoreItems = getMoreItems || (() => {});
    this.getItems = getItems || (() => {});
    this.getStyles = getStyles || (() => {});
    this.getContainer = getContainer || (() => {});
    this.getTotalItemsCount = getTotalItemsCount || (() => {});
    this.onBlueprintReadyCallback = onBlueprintReady || (() => {});
  }

  updateFunctions({
    getMoreItems,
    getItems,
    getContainer,
    getStyles,
    onBlueprintReady,
    getTotalItemsCount,
  }) {
    this.getMoreItems = getMoreItems || (() => {});
    this.getItems = getItems || (() => {});
    this.getStyles = getStyles || (() => {});
    this.getContainer = getContainer || (() => {});
    this.getTotalItemsCount = getTotalItemsCount || (() => {});
    this.onBlueprintReadyCallback = onBlueprintReady || (() => {});
  }

  fetchMoreItems(currentItemLength) {
    this.getMoreItems(currentItemLength);
  }
  fetchItems() {
    return this.getItems();
  }
  fetchStyles() {
    return this.getStyles();
  }
  fetchDimensions() {
    return this.getContainer();
  }

  onBlueprintReady({ blueprint, blueprintChanged }) {
    this.onBlueprintReadyCallback(blueprint, blueprintChanged);
  }

  isUsingCustomInfoElements() {
    return true;
  }
}
